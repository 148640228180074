<template>
    <page title="班级人脸采集" class="class-face-page" :hasBack="true" textAlign="left">
        <form action="/" class="search-wrapper">
            <van-search @search="search"  @cancel="search"  v-model="searchValue" class="search" placeholder="请输入搜索吧..." />
        </form>
        <div class="choose-list">
            <div class="choose-item" @click="changeClassPickVisible">
                <span v-if="!currentChooseClass.id">请选择班级</span>
                <span v-else>{{currentChooseClass.name}}</span>
                <img src="@/assets/images/common/down.png" alt="" />
            </div>
            <div class="choose-item" @click="changeRolePickVisible">
                <span v-if="!currentChooseRole.id">请选择权限</span>
                <span v-else>{{currentChooseRole.name}}</span>
                <img src="@/assets/images/common/down.png" alt="" />
            </div>
        </div>
        <van-popup  v-model:show="classPickerVisible" round position="bottom">
            <van-picker
                title="选择班级"
                :default-index="currentChooseClass.id ?  classList.value?.findIndex(item => item.id == currentChooseClass.id) : 0"
                :columns="classList"
                @confirm="classConfirm"
                @cancel="changeClassPickVisible"
            />
        </van-popup>
        <van-popup  v-model:show="rolePickerVisible" round position="bottom">
            <van-picker
                title="选择权限"
                :default-index="currentChooseRole.id ?  statusList.value?.findIndex(item => item.id == currentChooseRole.id) : 0"
                :columns="statusTypeList"
                @confirm="roleConfirm"
                @cancel="changeRolePickVisible"
            />
        </van-popup>
        <van-list
            @load="onLoad"
            v-model:loading="loading"
            :finished="finished"
            finished-text="已经到底了"
            :offset="50"
            :immediate-check="false"
            class="face-list"
            v-if="!faceListHasLoad || faceList.length"
        >
            <van-row gutter="30">
                <van-col span="8" v-for="item in faceList" :key="item.id">
                    <div class="face-item">
                        <div class="avatar-wrapper">
                            <div class="avatar" @click="changeCurrentUserId(item.studentUserid)">
                                <van-uploader :before-read="beforeRead">
                                    <van-image v-if="item.photo" class="vant-img" fit="cover" :src="item.photo" />
                                    <img class="default-img" v-else :src="require('@/assets/images/user/default_avatar.png')" alt="" />
                                </van-uploader>
                            </div>
                            <div
                                @click="showSetting(item.studentUserid, item.isoncampus)"
                                class="status"
                                :style="{ background: convertStatus(item.isoncampus).color }"
                            >
                                {{ convertStatus(item.isoncampus).text }}
                            </div>
                            <img class="error" @click="showMessage(item.faceCollectDesc)" v-if="item.faceCollectStatus == 0" src="@/assets/images/common/error.png" alt="">
                        </div>
                        <div class="name">{{ item.studentName }}</div>
                    </div>
                </van-col>
            </van-row>
        </van-list>
        <div v-else class="empty-wrapper">
            <empty />
        </div>
        <van-popup v-model:show="popupShow" position="bottom" :style="{ height: '352px' }">
            <common-block class="status-wrapper" :border="false" title="走/住读权限设置">
                <template v-slot:right-icon>
                    <van-icon name="cross" class="close" @click="changeStatus" />
                </template>
                <div class="status-list">
                    <van-radio-group v-model="statusType">
                        <div
                            class="status-item"
                            v-for="item in statusList"
                            :key="item.status"
                            :class="{ active: statusType === item.status }"
                        >
                            <span>{{ item.name }}</span>
                            <van-radio :name="item.status" class="radio" checked-color="#FF467F" />
                        </div>
                    </van-radio-group>
                </div>
                <div class="confirm-btn">
                    <van-button round type="primary" @click="confirmSetting">确定</van-button>
                </div>
            </common-block>
        </van-popup>
    </page>
</template>
<script>
    import Page from '@/components/page/Page';
    import Empty from '@/components/empty/Empty';
    import CommonBlock from '@/components/commonBlock/CommonBlock.vue';
    import Compressor from 'compressorjs';
    import {
        List,
        Col,
        Row,
        Popup,
        Button,
        Radio,
        RadioGroup,
        Icon,
        Image as VanImage,
        Uploader,
        Toast,
        Search,
        Form,
        Picker,
        Field
    } from 'vant';
    import { reactive, ref } from 'vue';
    import commonRequest from '@/services';
    import paramsParse from '@/utils/paramsParse';
    import {getStorage, setStorage} from "@/utils/storage";
    export default {
        name: 'ClassFace',
        components: {
            [Page.name]: Page,
            [List.name]: List,
            [Empty.name]: Empty,
            [Col.name]: Col,
            [Row.name]: Row,
            [Popup.name]: Popup,
            [CommonBlock.name]: CommonBlock,
            [Button.name]: Button,
            [Radio.name]: Radio,
            [RadioGroup.name]: RadioGroup,
            [Icon.name]: Icon,
            [VanImage.name]: VanImage,
            [Uploader.name]: Uploader,
            [Search.name]: Search,
            [Form.name]: Form,
            [Picker.name]: Picker,
            [Field.name]: Field,
        },
        setup() {
            const faceList = ref([
                // { id: 1, avatar: require('@/assets/images/user/default_avatar.png'), name: '张啦啦', status: 1 },
                // { id: 2, avatar: require('@/assets/images/user/default_avatar.png'), name: '张啦啦', status: 2 },
                // { id: 3, avatar: require('@/assets/images/user/default_avatar.png'), name: '张啦啦', status: 3 },
                // { id: 4, avatar: require('@/assets/images/user/default_avatar.png'), name: '张啦啦' },
                // { id: 5, avatar: require('@/assets/images/user/default_avatar.png'), name: '张啦啦', status: 1 },
                // { id: 6, avatar: require('@/assets/images/user/default_avatar.png'), name: '张啦啦', status: 1 },
                // { id: 7, avatar: require('@/assets/images/user/default_avatar.png'), name: '张啦啦', status: 1 },
                // { id: 8, avatar: require('@/assets/images/user/default_avatar.png'), name: '张啦啦', status: 1 },
                // { id: 9, avatar: require('@/assets/images/user/default_avatar.png'), name: '张啦啦', status: 1 },
                // { id: 10, avatar: require('@/assets/images/user/default_avatar.png'), name: '张啦啦', status: 1 },
            ]);
            const searchValue = ref('');
            const faceListHasLoad = ref(true);
            const loading = ref(false);
            const finished = ref(false);
            const pageNum = ref(0);
            const initPage = 15;
            const currentChooseClass = reactive({
                id: '',
                name: ''
            });
            const currentChooseRole = reactive({
                id: '',
                name: ''
            });
            const classPickerVisible = ref(false);
            const rolePickerVisible = ref(false);

            const changeClassPickVisible = () => {
                classPickerVisible.value = !classPickerVisible.value
            };
            const changeRolePickVisible = () => {
                rolePickerVisible.value = !rolePickerVisible.value
            };
            let currentStudentUserid = null;
            const changeCurrentUserId = (id) => {
                console.log('id', id);
                currentStudentUserid = id
            }

            const onLoad = () => {
                pageNum.value++;
                loading.value = true;
                commonRequest({
                    action: 12007,
                    classId: currentChooseClass.id,
                    pageNum: pageNum.value,
                    pageSize: initPage,
                    keyword: searchValue.value,
                    isoncampus: currentChooseRole.id
                }).then((res) => {
                    faceListHasLoad.value = true;
                    if (res.rows) {
                        loading.value = false;
                        faceList.value = faceList.value.concat(res.rows)
                        if (res.rows.length < initPage) {
                            finished.value = true;
                        }
                    }
                });
            };

            const convertStatus = (status) => {
                if (status == 1) {
                    return {
                        text: '走读',
                        color: '#EE8A32',
                    };
                } else if (status == 2) {
                    return {
                        text: '住读',
                        color: '#07C160',
                    };
                } else if (status == 3) {
                    return {
                        text: '全天开放',
                        color: '#FF467F',
                    };
                }
            };
            const statusTypeList = ref([
                { text: '走读', id: '1' },
                { text: '住读', id: '2' },
                { text: '全天开放', id: '3' },
            ]);
            const statusList = ref([
                { name: '走读', status: '1' },
                { name: '住读', status: '2' },
                { name: '全天开放', status: '3' },
            ]);
            const popupShow = ref(false);

            const changeStatus = () => {
                popupShow.value = !popupShow.value;
            };
            const statusType = ref(1);
            const beforeRead = (file) => {
                new Compressor(file, {
                    quality: 0.6,
                    success(result) {
                        // Blob 转换为 File
                        const files = new window.File([result], file.name, {type: file.type});
                        const timestamp = Math.floor(new Date().getTime() / 1000);
                        const signData = {
                            action: 10001,
                            type: 'images',
                            timestamp,
                        };
                        const token = getStorage('token');
                        if (token) {
                            signData.token = token;
                        }
                        const sign = paramsParse(signData);
                        const formData = new FormData();
                        formData.append('action', 10001);
                        formData.append('type', 'images');
                        formData.append('timestamp', timestamp);
                        formData.append('sign', sign);
                        formData.append('token', token);
                        formData.append('files', files);
                        const toast = Toast.loading({
                            message: '上传中...',
                            forbidClick: true,
                            loadingType: 'spinner',
                            duration: 0
                        });
                        commonRequest(formData).then((res) => {
                            if (res.data) {
                                const photo = res.data[0]?.aliyunPath;
                                commonRequest({ action: 12008, studentUserid: currentStudentUserid, photo}).then(() => {
                                    toast.clear();
                                    Toast('修改成功');
                                    const index = faceList.value.findIndex(item => item.studentUserid === currentStudentUserid);
                                    faceList.value[index].photo = photo;
                                })
                            }
                        });
                    }
                })

            };

            const classList = ref([]);

            commonRequest({ action: 12009 }).then((res) => {
                if (res.data) {
                    classList.value = res.data.map(item => ({
                        id: item.classId,
                        text: item.className
                    }));
                    if (res.data.length) {
                        const chooseStudentId = getStorage('chooseClassId');
                        if (!chooseStudentId) {
                            currentChooseClass.id = res.data[0].classId;
                            currentChooseClass.name = res.data[0].className;
                            setStorage('chooseClassId', res.data[0].classId);
                        } else {
                            currentChooseClass.id = chooseStudentId;
                            currentChooseClass.name = res.data.find(item => item.classId == chooseStudentId)?.className;
                        }
                    }
                    if (res.data.length) {
                        onLoad();
                    }
                }
            });

            const showSetting = (studentUserid, isoncampus) => {
                statusType.value = isoncampus
                currentStudentUserid = studentUserid;
                changeStatus();
            };
            const confirmSetting = () => {
                commonRequest({ action: 12008, studentUserid: currentStudentUserid, oncampus: statusType.value}).then(() => {
                    changeStatus();
                    Toast('修改成功');
                    const index = faceList.value.findIndex(item => item.studentUserid === currentStudentUserid);
                    faceList.value[index].isoncampus = statusType.value;
                })
            };
            const classConfirm = (value) => {
                changeClassPickVisible();
                if (value.id != currentChooseClass.id) {
                    currentChooseClass.id = value.id;
                    currentChooseClass.name = value.text;
                    setStorage('chooseClassId', value.id);
                    faceList.value = [];
                    pageNum.value = 0;
                    finished.value = false;
                    faceListHasLoad.value = false;
                    onLoad();
                }
            };
            const roleConfirm = (value) => {
                changeRolePickVisible();
                if (value.id != currentChooseRole.id) {
                    currentChooseRole.id = value.id;
                    currentChooseRole.name = value.text;
                    faceList.value = [];
                    pageNum.value = 0;
                    finished.value = false;
                    faceListHasLoad.value = false;
                    onLoad();
                }
            };
            const search = () => {
                faceList.value = [];
                pageNum.value = 0;
                finished.value = false;
                faceListHasLoad.value = false;
                onLoad();
            };
            const showMessage = (message) => {
                if (message) {
                    Toast(message);
                }
            };
            return {
                faceList,
                faceListHasLoad,
                finished,
                loading,
                onLoad,
                convertStatus,
                changeStatus,
                popupShow,
                statusType,
                statusList,
                beforeRead,
                confirmSetting,
                showSetting,
                searchValue,
                currentChooseClass,
                classPickerVisible,
                changeClassPickVisible,
                classConfirm,
                classList,
                currentChooseRole,
                rolePickerVisible,
                changeRolePickVisible,
                roleConfirm,
                statusTypeList,
                search,
                changeCurrentUserId,
                showMessage
            };
        },
    };
</script>
<style lang="less" scoped>
    .class-face-page {
        box-sizing: border-box;
        padding-bottom: 20px;
        /*display: flex;*/
        /*flex-direction: column;*/
    }
    /*.search-wrapper {*/
    /*    flex-shrink: 0;*/
    /*}*/
    /*.choose-list {*/
    /*    flex-shrink: 0;*/
    /*}*/
    /*.face-list {*/
    /*    flex: 1;*/
    /*    overflow: auto;*/
    /*}*/
    .face-item {
        margin-bottom: 25px;
    }
    .avatar-wrapper {
        height: 141px;
        background: #e2e2e2;
        border-radius: 5px;
        position: relative;
        display: flex;
        flex-direction: column;
        .error {
            position: absolute;
            top: 5px;
            right: 4px;
            width: 20px;
        }
        .vant-img {
            width: 100%;
            height: 100%;
        }
        /deep/ .van-uploader{
            height: 100%;
        }
        /deep/ .van-uploader__wrapper{
            height: 100%;
        }
        .avatar {
            flex: 1;
            height: 120px;
            position: relative;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            .default-img {
                max-width: 100%;
                max-height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
                right: 0;
                margin: auto;
            }
        }
        .status {
            width: 100%;
            border-radius: 5px 0px 5px 0px;
            height: 21px;
            flex-shrink: 0;
            line-height: 21px;
            padding: 0 9px;
            box-sizing: border-box;
            font-size: 12px;
            font-family: PingFang SC, serif;
            font-weight: 400;
            color: #ffffff;
            text-align: center;
        }
    }
    .name {
        font-size: 15px;
        font-family: PingFang SC, serif;
        font-weight: bold;
        line-height: 17px;
        color: #333333;
        text-align: center;
        margin-top: 6px;
    }
    .empty-wrapper {
        height: 350px;
    }
    /deep/ .van-popup--bottom {
        border-radius: 5px 5px 0px 0px;
    }
    .status-wrapper {
        margin-top: 0;
    }
    .status-list {
        border-radius: 5px;
        background: #ffffff;
        margin-left: 5px;
        .status-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            /*padding: 0 10px;*/
            box-sizing: border-box;
            height: 50px;
            border-bottom: 1px solid #f0f0f0;
        }
        span {
            font-size: 14px;
            font-family: PingFang SC, serif;
            font-weight: bold;
            color: #333333;
        }
        .radio {
            margin-right: 15px;
        }
    }
    .confirm-btn {
        margin-top: 50px;
        text-align: center;
        /deep/ .van-button.van-button--primary {
            padding: 0 90px;
        }
    }
    .close {
        font-size: 16px;
    }
    .choose-list{
        margin-top: 15px;
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        .choose-item {
            display: flex;
            align-items: center;
            &:nth-of-type(1) {
                margin-right: 35px;
            }
            span {
                font-size: 14px;
                font-family: PingFang SC, serif;
                color: #333333;
            }
            img {
                width: 8px;
                height: 5px;
                margin-left: 3px;
            }
        }
    }
</style>
